import React, { useState, useEffect, Fragment, useReducer } from "react";
import PlainPage from "../../templates/plain-page";
import {
  UsersTable,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  SelectColumnFilter,
} from "../../components/UsersTable";
import { db, firebaseFunc } from "../../components/firebase/firestore";
import { formatDate, getAge } from "../../utils/utils";
import { getStatus, getIteration } from "../ref-users";

const getData = () =>
  Promise.all([
    db.collection("usersInfo").get(),
    db.collection("points").get(),
    db.collection("users").get(),
    db.collection("verification").get(),
    db.collection("ref").get(),
  ])
    .then((querySnapshots) => {
      const res = [];

      const usersInfo = querySnapshots[0];
      const points = querySnapshots[1];
      const users = querySnapshots[2];
      const verification = querySnapshots[3];
      const ref = querySnapshots[4];

      const extractRow = (id, qSnapshot) => {
        const data = qSnapshot.docs.find((user) => user.id === id);
        return data ? data.data() : {};
      };

      usersInfo.docs.forEach((info) => {
        const infoRow = info.data();
        const pointsRow = extractRow(info.id, points);
        const userRow = extractRow(info.id, users);
        const verificationRow = extractRow(info.id, verification);
        const refRow = extractRow(info.id, ref);
        const inviterRow = extractRow(refRow.inviter_uid, users);

        res.push({
          ...infoRow,
          ...pointsRow,
          ...userRow,
          ...verificationRow,
          ...refRow,
          id: info.id,
          status: ["verified", "rejected"].includes(verificationRow.status)
            ? verificationRow.status
            : "awaiting",
          signUpDate: infoRow.signUpDate
            ? new Date(infoRow.signUpDate)
            : new Date(0),
          signUpDateFormatted: infoRow.signUpDate
            ? formatDate(new Date(infoRow.signUpDate))
            : "",
          age: getAge(userRow.birth),
          // points_iteration: getIteration(pointsRow),
          points_status: getStatus(pointsRow),
          isBlocked: infoRow.isBlocked ? "Yes" : "No",
          inviter_name: inviterRow.first_name
            ? `${inviterRow.first_name} ${inviterRow.surname}`
            : "",
        });
      });

      return res;
    })
    .catch((e) => {
      alert(e);
      return [];
    });

const verify = (verifierId, userId, dataRaw, refreshData) => {
  const button = document.getElementById(`verify-${userId}`);
  button.classList.add("is-loading");
  const ref = db.collection("verification").doc(userId);
  ref
    .get()
    .then((doc) => {
      if (!doc.exists) {
        ref.set({ status: "verified", changer_uid: verifierId });
        // TODO execute if inviter is current user
        // reduceNotVerifiedUsersCount();
        const user = dataRaw[dataRaw.findIndex((u) => u.id === userId)];
        user.status = "verified";
        user.changer_uid = verifierId;
        refreshData([...dataRaw]);
      }
    })
    .catch((e) => alert(e))
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const block = (bannerId, userId, dataRaw, refreshData) => {
  const button = document.getElementById(`block-${userId}`);
  button.classList.add("is-loading");
  const blockUser = firebaseFunc.httpsCallable("blockUser");
  blockUser({ bannerId, userId })
    .then(({ data: resData }) => {
      if (resData) {
        const user = dataRaw[dataRaw.findIndex((u) => u.id === userId)];
        user.isBlocked = "Yes";
        refreshData([...dataRaw]);
        alert("User is banned!");
      } else {
        alert("Error banning user! Details are in Firebase console");
      }
    })
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const unblock = (bannerId, userId, dataRaw, refreshData) => {
  const button = document.getElementById(`block-${userId}`);
  button.classList.add("is-loading");
  const unblockUser = firebaseFunc.httpsCallable("unblockUser");
  unblockUser({ bannerId, userId })
    .then(({ data: resData }) => {
      if (resData) {
        const user = dataRaw[dataRaw.findIndex((u) => u.id === userId)];
        user.isBlocked = "No";
        refreshData([...dataRaw]);
        alert("User is unbanned!");
      } else {
        alert("Error unbanning user! Details are in Firebase console");
      }
    })
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const createReducer = (dataRaw, refreshData) => (state, action) => {
  switch (action.type) {
    case "verify":
      verify(action.verifierId, action.userId, dataRaw, refreshData);
      return [];
    case "block":
      block(action.bannerId, action.userId, dataRaw, refreshData);
      return [];
    case "unblock":
      unblock(action.bannerId, action.userId, dataRaw, refreshData);
      return [];
    default:
      throw new Error("Unknown action.type");
  }
};

const UserCell = ({ cellIdPrefix, rowId, uid, value }) => (
  <div>
    <a
      className="dotted"
      onClick={() => {
        const cell = document.getElementById(`${cellIdPrefix}${rowId}`);
        // cell.classList.remove("dotted");
        cell.innerText = "loading...";

        firebaseFunc
          .httpsCallable("getUserEmailById")({ uid })
          .then(({ data }) => {
            cell.innerText = `${data.email ? data.email : "no email"}\n${
              data.phone ? data.phone : "no phone"
            }\n${uid}`;
          })
          .catch((error) => {
            cell.innerText = "internal error";
            console.log(error);
          });
      }}
    >
      {value}
    </a>
    <p id={`${cellIdPrefix}${rowId}`} />
  </div>
);

const Content = ({ currentUser }) => {
  const [columnsRaw, setColumnsRaw] = useState([
    {
      Header: "Profile",
      columns: [
        // users
        {
          Header: "First name",
          accessor: "first_name",
          Cell: ({ row, data }) => (
            <UserCell
              cellIdPrefix="first_name"
              rowId={data[row.index].id}
              uid={data[row.index].id}
              value={data[row.index].first_name}
            />
          ),
        },
        {
          Header: "Surname",
          accessor: "surname",
          Cell: ({ row, data }) => (
            <UserCell
              cellIdPrefix="surname"
              rowId={data[row.index].id}
              uid={data[row.index].id}
              value={data[row.index].surname}
            />
          ),
        },
        {
          Header: "Date of birth",
          accessor: "birth",
          disableSorting: true,
          // sortType: "datetime",
          // Cell: ({ row, data }) => data[row.index].birthFormatted
        },
        {
          Header: "CV",
          accessor: "cv",
        },
        {
          Header: "Languages",
          accessor: "languages",
        },
        {
          Header: "Sector",
          accessor: "sector",
          Filter: SelectColumnFilter,
          filter: "equals",
        },
        {
          Header: "Institution/Organisations name",
          accessor: "organization",
        },
        {
          Header: "Institution/Organisations website",
          accessor: "website",
        },
        {
          Header: "Profile headline",
          accessor: "headline",
        },
        {
          Header: "Country",
          accessor: "country",
          Filter: SelectColumnFilter,
          filter: "includes",
        },
        {
          Header: "Account type",
          accessor: "type",
          Filter: SelectColumnFilter,
          filter: "includes",
        },
      ],
    },
    {
      Header: "Info",
      columns: [
        // calculated
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Age",
          accessor: "age",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        },
        {
          Header: "Verification status",
          accessor: "status", // from verification document
          Filter: SelectColumnFilter,
          filter: "includes",
        },
        {
          Header: "Signup date",
          accessor: "signUpDate", // from usersInfo document
          sortType: "datetime",
          Cell: ({ row, data }) => data[row.index].signUpDateFormatted,
        },
        {
          Header: "Banned",
          accessor: "isBlocked", // from usersInfo document
          Filter: SelectColumnFilter,
          filter: "equals",
        },
        // verification
        {
          Header: "Verifier/rejecter id",
          accessor: "changer_uid",
        },
        // ref
        {
          Header: "Inviter id",
          accessor: "inviter_uid",
          Cell: ({ row, data }) => (
            <UserCell
              cellIdPrefix="inviter_uid"
              rowId={data[row.index].id}
              uid={data[row.index].inviter_uid}
              value={data[row.index].inviter_uid}
            />
          ),
        },
        {
          Header: "Inviter name",
          accessor: "inviter_name",
          Cell: ({ row, data }) => (
            <UserCell
              cellIdPrefix="inviter_name"
              rowId={data[row.index].id}
              uid={data[row.index].inviter_uid}
              value={data[row.index].inviter_name}
            />
          ),
        },
      ],
    },
    {
      Header: "Points",
      columns: [
        // points
        {
          Header: "Points",
          accessor: "points",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        },
        // calculated
        // {
        //   Header: "Iteration",
        //   accessor: "points_iteration"
        // },
        {
          Header: "Points collection status",
          accessor: "points_status",
          Filter: SelectColumnFilter,
          filter: "includes",
        },
      ],
    },
    {
      Header: "Actions",
      columns: [
        // points
        {
          Header: "Actions",
          accessor: "actions",
          disableFilters: true,
          Cell: ({ row }) => (
            <span className="is-pulled-right nowrap">
              {["awaiting", "rejected"].includes(row.values.status) && (
                <button
                  id={`verify-${row.values.id}`}
                  type="button"
                  className="button is-primary m-r-sm"
                  onClick={() => {
                    dispatch({
                      type: "verify",
                      verifierId: currentUser.uid,
                      userId: row.values.id,
                    });
                  }}
                >
                  Verify
                </button>
              )}
              <div className="dropdown is-hoverable is-right">
                <div className="dropdown-trigger">
                  <button
                    id={`block-${row.values.id}`}
                    type="button"
                    className="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <span className="icon is-small">
                      <i className="fas fa-ellipsis-v" aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        dispatch({
                          type:
                            row.values.isBlocked === "Yes"
                              ? "unblock"
                              : "block",
                          bannerId: currentUser.uid,
                          userId: row.values.id,
                        });
                      }}
                    >
                      {row.values.isBlocked === "Yes"
                        ? "Unban user"
                        : "Ban user"}
                    </a>
                  </div>
                </div>
              </div>
            </span>
          ),
        },
      ],
    },
  ]);
  const hiddenColumns = [
    "birth",
    "cv",
    "languages",
    "sector",
    "organization",
    "website",
    "headline",
    "country",
    "type",
    "id",
    "status",
    "signUpDate",
    "isBlocked",
    "changer_uid",
    "inviter_uid",
  ];
  const [dataRaw, setDataRaw] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);
  const [usersAwaiting, setUsersAwaiting] = useState(0);
  const [usersRejected, setUsersRejected] = useState(0);

  const columns = React.useMemo(() => columnsRaw, [columnsRaw]);
  const data = React.useMemo(() => dataRaw, [dataRaw]);

  const refreshData = (res) => {
    setDataRaw(res);
    setUsersTotal(res.length);
    setUsersAwaiting(res.filter((u) => u.status === "awaiting").length);
    setUsersRejected(res.filter((u) => u.status === "rejected").length);
  };

  // Have to use callback due to reducer firing twice because of component rerender https://stackoverflow.com/a/55056623
  const memoizedReducer = React.useCallback(
    createReducer(dataRaw, refreshData),
    [dataRaw],
  );
  const [state, dispatch] = useReducer(memoizedReducer, []);

  useEffect(() => {
    getData().then((res) => {
      refreshData(res);
    });
  }, []);

  return (
    <div className="column is-12">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Manage users <span className="tag is-light m-l-xs">{usersTotal}</span>
      </h2>
      <div className="columns is-desktop">
        <div className="column is-12">
          <UsersTable
            columns={columns}
            data={data}
            usersAwaiting={usersAwaiting}
            usersRejected={usersRejected}
            hiddenColumnsInit={hiddenColumns}
          />
        </div>
      </div>
    </div>
  );
};

const UserList = () => {
  return <PlainPage Content={Content} isPrivate isFluid />;
};

export default UserList;
